/*==========================================
    Variables
===========================================*/
export const body = document.body;
export const header = document.querySelector("header#o-header");

export const megaMenuOverlay = header.querySelector(".m-mega-menu-overlay");
export const siteOverlay = document.querySelector("#a-site-overlay");

export const preLoaderOverlay = document.querySelector("#a-pre-load-overlay");

/*==========================================
    Methods 
===========================================*/

export const showElement = element => {
    element.classList.add("v-open");

    setTimeout(() => {
        element.classList.add("v-show-background");
    }, 50);

    setTimeout(() => {
        element.classList.add("v-visible");
    }, 300);
};

export const hideElement = element => {
    element.classList.remove("v-visible");

    setTimeout(() => {
        element.classList.remove("v-show-background");
    }, 50);

    setTimeout(() => {
        element.classList.remove("v-open");
    }, 50);
};

export const getNextSiblings = element => {
    var arraySib = [];
    while ((element = element.nextElementSibling)) {
        arraySib.push(element);
    }
    return arraySib;
};




