import {
    hideSiteOverlay,
    displaySiteOverlay,
    doesElementHaveParentWithClass
} from "../../websiteUtil/src/utilities";
import { hideElement, showElement, body, header } from "./utilities.js";
import { resetMegaMenuOverlay, resizeMegaMenuOverlay } from "./index.js";

/*==========================================
 Variables
===========================================*/

const hamburgerMenuOpenClass = "v-hamburger-menu-open";

const hamburgerMenuTrigger = header.querySelector(
    ".js-header-hamburger-menu-trigger"
);

const hamburgerMenuBlock = header.querySelector(".m-hamburger-menu-block");

/*==========================================
 Click Events Listeners
===========================================*/

export const initHamburgerMenu = () => {
    hamburgerMenuTrigger.addEventListener("click", evt => { 
        evt.preventDefault();
        hamburgerMenuTriggerActions();
    });

    body.addEventListener("mouseover", evt => bodyEventListeners(evt));
    body.addEventListener("click", evt => bodyEventListeners(evt));

    const bodyEventListeners = evt => { 
        const element = evt.target;
        if (
            body.classList.contains(hamburgerMenuOpenClass) &&
            !doesElementHaveParentWithClass(element, "o-header")
        ) {
            closeHamburgerMenu();
            resetMegaMenuOverlay();
        }
    };
};

/*==========================================
 Header Trigger Events
===========================================*/

const hamburgerMenuTriggerActions = () => { 
    if (!hamburgerMenuTrigger.classList.contains("v-active")) {
        openHamburgerMenu();
    } else {
        closeHamburgerMenu();
    }
};

/*==========================================
 Hamburger Menu Events
===========================================*/

const openHamburgerMenu = () => { 
    if (hamburgerMenuTrigger) {
    resetMegaMenuOverlay();
    displaySiteOverlay(hamburgerMenuOpenClass);
    hamburgerMenuTrigger.classList.add("v-active");
    showElement(hamburgerMenuBlock);
    resizeMegaMenuOverlay(hamburgerMenuBlock);
}
};

export const closeHamburgerMenu = () => {
    if (hamburgerMenuTrigger) {
        hideSiteOverlay(hamburgerMenuOpenClass);
        hamburgerMenuTrigger.classList.remove("v-active");
        hideElement(hamburgerMenuBlock);
        // closeAllToggleMenus();
        resetMegaMenuOverlay();
    }
};


