import {
    hideSiteOverlay,
    displaySiteOverlay,
    getParentElementWithTag,
    doesElementHaveParentWithClass,
    isMobileDevice
} from "../../websiteUtil/src/utilities";
import { body } from "./utilities.js";
import { resetMegaMenuOverlay } from "./index.js";
import { closeHamburgerMenu } from "./hamburger-menu.js";
import hoverintent from "hoverintent";

/*==========================================
 Variables
===========================================*/

const dropdownMenuOpenClass = "v-dropdown-open";
const dropdownMenuBlock = document.querySelector(".m-dropdown-menu-block");
// const dropdownMenuTriggers = [
//     ...document.querySelectorAll(".m-dropdown-menu-block > li")
// ];
const dropdownMenuTriggers =[].slice.call
    (document.querySelectorAll(".m-dropdown-menu-block > li")
    );

/*==========================================
 Click Events Listeners
===========================================*/

export const initDropdownMenu = () => {
    if (isMobileDevice()) {  
        dropdownMenuBlock.addEventListener("click", evt => {
            const linkElement = getParentElementWithTagAndClass(
                evt.target,
                "a",
                "v-has-children"
            );

            if (linkElement) {
                if (linkElement.classList.contains("v-clicked")) {
                    linkElement.classList.remove("v-clicked");
                } else {
                    evt.preventDefault();
                    const linkElement = evt.target;
                    const liElement = getParentElementWithTag(
                        linkElement,
                        "li"
                    );

                    if (liElement) {
                        openDropdownMenu(liElement);
                        linkElement.classList.add("v-clicked");
                    }
                }
            }
        });
        
    } else {
        dropdownMenuTriggers.forEach(element => {
            hoverintent(
                element,
                evt => {
                    const target = evt.target;
                    const liElement = getParentElementWithTag(target, "li");

                    if (!liElement.classList.contains("v-active")) {
                        openDropdownMenu(liElement);
                    } else {
                        closeAllDropdownMenus();
                    }
                },
                () => {}
            );
        });
    }

    //Body Mouse over events to close open menus
    body.addEventListener("mouseover", evt => bodyEventListeners(evt));
    body.addEventListener("click", evt => bodyEventListeners(evt));

    const bodyEventListeners = evt => {
        const element = evt.target;

        if (
            body.classList.contains(dropdownMenuOpenClass) &&
            !doesElementHaveParentWithClass(element, "m-dropdown-menu-block")
        ) {
            closeAllDropdownMenus();
        }
    };
};


/*==========================================
 Dropdown Menu
===========================================*/

const openDropdownMenu = liElement => {
    closeAllDropdownMenus();
    closeHamburgerMenu();
    resetMegaMenuOverlay();

    //const hasNestedUlElement = [...liElement.querySelectorAll("ul")].length;
   const hasNestedUlElement = [].slice.call(liElement.querySelectorAll("ul")).length;

    if (hasNestedUlElement) {
        displaySiteOverlay(dropdownMenuOpenClass);
        liElement.classList.add("v-active");
    }
};

const closeAllDropdownMenus = () => {
    hideSiteOverlay(dropdownMenuOpenClass);
    dropdownMenuTriggers.forEach(element => {
        element.classList.remove("v-active");
    });
};



