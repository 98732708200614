import {
    getParentElementWithTag,
    getParentElementWithTagAndClass,
    getParentElementWithClass
} from "../../websiteUtil/src/utilities";
import { body, header } from "./utilities.js";
import { resizeMegaMenuOverlay } from "./index.js";

/*==========================================
 Variables
===========================================*/

/*==========================================
 Click Events Listeners
===========================================*/

export const initToggleMenu = () => {
    body.addEventListener("click", evt => {
        const element = evt.target;
        const buttonElement = getParentElementWithTagAndClass(
            element,
            "span",
            "js-toggle-menu-trigger"
        );

        if (buttonElement) {
            evt.preventDefault();
            toggleMenuTriggerActions(buttonElement);
        }
    });
};

/*==========================================
 Header Trigger Events
===========================================*/

const toggleMenuTriggerActions = trigger => {
    if (!trigger.parentElement.classList.contains("v-open")) {
        openToggleMenu(trigger);
    } else {
        closeToggleMenu(trigger);
    }
};

/*==========================================
 Toggle Menu
===========================================*/

const openToggleMenu = trigger => {
    const closestUlList = getParentElementWithTag(trigger, "li");
    const topLevelBlock = getParentElementWithClass(trigger, "js-header-block");

    if (closestUlList) {
        const parentUl = getParentElementWithTag(closestUlList, "ul");
        if (parentUl) {
            const openSiblingList = parentUl.querySelector(
                ".js-toggle-menu-trigger.v-active"
            );
            if (openSiblingList) {
                closeToggleMenu(openSiblingList);
            }
        }

        closestUlList.classList.add("v-open");

        resizeMegaMenuOverlay(topLevelBlock);
    }
};

export const closeToggleMenu = trigger => {
    const closestUlList = getParentElementWithTag(trigger, "li");
    const topLevelBlock = getParentElementWithClass(trigger, "js-header-block");

    if (closestUlList) {
        const nestedTriggers = [].slice.call(closestUlList.querySelectorAll(
                "li.v-open .js-toggle-menu-trigger:first-child"
            )
        );
        closestUlList.classList.remove("v-open");

        if (nestedTriggers) {
            nestedTriggers.forEach(nestedTrigger => {
                closeToggleMenu(nestedTrigger);
            });
        }
    }

    resizeMegaMenuOverlay(topLevelBlock);
};

export const closeAllToggleMenus = () => {
    // const openToggleMenus = [
    //     ...header.querySelectorAll(".m-toggle-menu-block li.v-open")
    // ];
    const openToggleMenus = [].slice.call
        (header.querySelectorAll(".m-toggle-menu-block li.v-open")
        );
    openToggleMenus.forEach(menu => {
        // const triggers = [
        //     ...menu.querySelectorAll(
        //         "li.v-open .js-toggle-menu-trigger:first-child"
        //     )
        // ];
        const triggers = [].slice.call
            (menu.querySelectorAll(
                "li.v-open .js-toggle-menu-trigger:first-child"
            )
            );
        menu.classList.remove("v-open");
    });
};



